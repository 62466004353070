import React from 'react'
import { DeferRender } from '../../DeferRender'
import { StaticQuery, graphql, Link } from 'gatsby'
import CartItem from './CartItem'

import Close from '../../../../images/icons/close.svg'

const CartPanel: React.FC = (data) => {
  const { cart, catalog, onCheckoutFn } = data

  const cartTotal = catalog.reduce((total, item) => {
    return total + item.item.priceCents * item.quantity
  }, 0)

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulRecipe {
            nodes {
              rectangularImage {
                file {
                  url
                }
              }
              slug
            }
          }
        }
      `}
      render={data => {
        const recipesBySlug = {}
        data.allContentfulRecipe.nodes.forEach((recipe) => {
          recipesBySlug[recipe.slug] = recipe?.rectangularImage?.file?.url
        })

        return (
          <div className="container">
            <DeferRender>
              {cart.recipes.length > 0 && <>
                <div className="row border-top mt-3 pt-3">
                  <div className="col-12">
                    <h4 className="display-5">Recipes I'm shopping</h4>
                    <p className="smaller">(This is just to help you keep track. Don't worry, we won't remove ingredients if you remove a recipe.)</p>
                  </div>
                </div>
                <div className="row scroll-horizontal py-2 mb-4">
                  {cart.recipes.map((recipeSlug, index) => {
                    return (
                      <div className="col-4" key={`recipe-${recipeSlug}-${index}`}>
                        <div className="position-relative">
                          <Close
                            className="cursor-pointer w15p position-absolute ml-1 mt-1 bg-white circle"
                            style={{ top: '-10px', left: '-10px' }}
                            onClick={() => {
                              cart.removeRecipe(recipeSlug)
                            }}
                          />
                          <Link to={`/recipes/${recipeSlug}`}>
                            <img className="img-fluid" src={recipesBySlug[recipeSlug]} />
                          </Link>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>}
              {
                catalog.map((item, index) => {
                  return <CartItem item={item} cart={cart} key={`cart-item-${index}`} />
                })
              }
            </DeferRender>
            <div className="row border-top pt-4 mt-3">
              <div className="offset-3 col-6">
                <h4 className="mb-0">Total</h4>
              </div>
              <div className="col-3">${(cartTotal / 100).toFixed(2)}</div>
              <div className="offset-3 col-9">
                <p className="display-5 font-">FREE Delivery!</p>
              </div>
            </div>
            <div className="row checkout-action position-relative py-4 mt-3">
              <div className="col-12 mx-auto">
                <button className="btn btn-primary btn-lg btn-block" onClick={onCheckoutFn}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export default CartPanel
