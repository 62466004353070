import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql, withPrefix } from 'gatsby'
import { ToastContainer } from 'react-toastify'
import favicon from '../images/favicon.ico'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/Header'
import Footer from '../components/Footer'
import CartProvider, { CartContext } from '../components/shared/CartProvider'
import ProductsProvider from '../components/shared/ProductsProvider'
import Cart from '../components/shared/Cart'

import 'react-toastify/dist/ReactToastify.css'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
    }
  }
}

const createMetaData = (data, childTitle, childDescription, shareTitle, metaImage) => {
  return [
    {
      name: `description`,
      content: childDescription || data.site.siteMetadata.description,
    },
    {
      property: `og:title`,
      content: shareTitle || childTitle || data.site.siteMetadata.title,
    },
    {
      property: `og:description`,
      content: childDescription || data.site.siteMetadata.description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:creator`,
      content: 'Joel Jackson',
    },
    {
      name: `twitter:title`,
      content: shareTitle || childTitle || data.site.siteMetadata.title,
    },
    {
      name: `twitter:description`,
      content: childDescription || data.site.siteMetadata.description,
    }
  ].concat(
    metaImage
      ? [
        {
          property: "og:image",
          content: metaImage.src,
        },
        {
          property: "og:image:width",
          content: metaImage.width,
        },
        {
          property: "og:image:height",
          content: metaImage.height,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ]
      : [
        {
          name: "twitter:card",
          content: "summary",
        },
      ]
  )
}

const IndexLayout: React.FC = ({ children, hideHeader, childTitle, childDescription, structuredData, shareTitle, metaImage }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      const metaData = createMetaData(data, childTitle, childDescription, shareTitle, metaImage)
      const [cartVisible, setCartVisble] = useState(true)

      return (
        <div className="body h-100 d-flex flex-column fixed-header">
          <Helmet
            title={childTitle || data.site.siteMetadata.title}
            meta={metaData}
            link={[{ rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }]}
          >
            <script src={withPrefix('tracking.js')} type="text/javascript" />
            <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
            {structuredData &&
              (<script type="application/ld+json">
                {JSON.stringify(structuredData)}
              </script>)
            }
            <link
              rel="stylesheet"
              type="text/css"
              charset="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
          </Helmet>
          <CartProvider>
            <ProductsProvider>
              <Header title={data.site.siteMetadata.title} hideHeader={hideHeader} />
              <ToastContainer toastClassName="toast-notification" />
              <div id="page-content" className={`${hideHeader ? "mt-0" : ""}`}>{children}</div>
              <CartContext.Consumer>
                {(cart) => (
                  <>
                    {
                      cart.visible && <div id="cart-overlay" onClick={cart.toggleCart} />
                    }
                  </>
                )}
              </CartContext.Consumer>
              <Cart />
            </ProductsProvider>
          </CartProvider>
          <Footer hideFooter={hideHeader} />
        </div >
      )
    }}
  />
)


export default IndexLayout
