import React, { useState, createRef } from 'react'

import DeliveryWindows from './DeliveryWindows'
import { buildCart } from '../../../../common/shopify'

const ExtraInfoPanel: React.FC = (data) => {
  let zipCodeInput = createRef();

  const { backFn, cartItems, recipeSlugs } = data

  const [zipMessage, setZipMessage] = useState(null)
  const [checkingOut, setCheckingOut] = useState(false)
  const [deliveryWindow, setDeliveryWindow] = useState(null)
  const [checkoutEnabled, setCheckoutEnabled] = useState(false)

  const checkZip = () => {
    const zipCode = zipCodeInput.current.value
    window.dataLayer?.push({ event: 'Zip Code Set', zipCode: zipCode });

    const zips = ['90232', '90025', '90291', '90404', '90402', '90403', '90401', '90405', '90064', '90066',
      '90292', '90034', '90230', '90035', 'ELMFRIENDS', 'elmfriends', 'Elmfriends', 'Chris20']

    const extraZips = ['90293', '90094', '90008', '90016', '90095', '90067', '90035', '90211', '90048', '90036',
      '90019', '90018', '90005', '90006', '90057', '90020', '90004', '90045']

    if ((zips.concat(extraZips)).indexOf(zipCode) != -1) {
      handleCheckout()
    } else {
      setZipMessage("Sorry we aren’t delivering to you yet 😓 stay tuned!")
    }
  }

  const keyPressed = (event) => {
    if (!checkoutEnabled) {
      return
    }

    setCheckoutEnabled(deliveryWindow != null && zipCodeInput.current.value != "")

    if (event.key === "Enter") {
      checkZip()
    }
  }

  const deliveryWindowChange = (newWindow) => {
    setDeliveryWindow(`${newWindow.name} ${newWindow.time}`)
    setCheckoutEnabled(newWindow != null && zipCodeInput.current.value != "")
  }

  const handleCheckout = async () => {
    setCheckingOut(true)
    const data = await buildCart(cartItems, deliveryWindow, recipeSlugs)
    localStorage.setItem('shopifyCartId', data.checkoutCreate.checkout.id)
    const url = data.checkoutCreate.checkout.webUrl

    window.location = url
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h4>Delivery zip code</h4>
          <input ref={zipCodeInput} onKeyPress={keyPressed} />
          <p className="small text-danger" dangerouslySetInnerHTML={{ __html: zipMessage }} />
        </div>
        <DeliveryWindows deliveryWindowChange={deliveryWindowChange} />
      </div>
      <div className="col-12 mt-3">
        {!checkingOut && <button className="btn btn-primary btn-lg btn-block mt-4 proceed-to-checkout" onClick={checkZip} disabled={!checkoutEnabled}>
          Checkout
            </button>}
        {checkingOut && <button className="btn btn-primary btn-lg btn-block mt-4 proceed-to-checkout" disabled="disabled">
          Proceeding ...
            </button>}
        {backFn && <a className="font-weight-bold position-absolute pt-4 mt-2 pl-1" onClick={backFn}>
          {"<"} Back to your basket
          </a>}
      </div>
    </div>
  )
}

export default ExtraInfoPanel
