import React from 'react'
import Plus from '../../../../images/svgs/plus.svg'
import Minus from '../../../../images/svgs/minus.svg'

const CartItem: React.FC = data => {
  const { cart, item } = data

  return (
    <div className="row item-row pt-3">
      <div className="col-3 text-center">
        <img src={item.item.picture?.fluid?.src} alt={item.name} className="img-fluid" />
      </div>
      <div className="col-6">
        <h5 className="font-weight-bold mb-1">{item.item.name}</h5>
        <p className="smaller mb-0">
          {item.item.brand} {item.item.quantityDescription &&
            ` - ${item.item.quantityDescription}`}
        </p>
        <Minus
          className="cursor-pointer fill-secondary w25p"
          onClick={() => {
            cart.subtract(item.item.sku)
          }}
        />
        <span className="px-2">{item.quantity}</span>
        <Plus
          className="cursor-pointer fill-primary w25p"
          onClick={() => {
            cart.add(item.item.sku)
          }}
        />
      </div>
      <div className="col-3">${((item.item.priceCents * item.quantity) / 100).toFixed(2)}</div>
    </div>
  )
}

export default CartItem
