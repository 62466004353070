import React, { Suspense } from 'react'
import useSWR from 'swr'

const fetcher = (url) => fetch(url).then((res) => res.json());

const deliveryWindows = [
  {
    dayOfWeek: 0,
    name: 'Sun',
    time: '8-11 am'
  },
  {
    dayOfWeek: 0,
    name: 'Sun',
    time: '4-7 pm'
  },
  {
    dayOfWeek: 2,
    name: 'Tues',
    time: '8-11 am'
  },
  {
    dayOfWeek: 2,
    name: 'Tues',
    time: '4-7 pm'
  },
  {
    dayOfWeek: 4,
    name: 'Thurs',
    time: '8-11 am'
  },
  {
    dayOfWeek: 4,
    name: 'Thurs',
    time: '4-7 pm'
  }
]

const nextDeliveryWindows = () => {
  const currentDayOfWeek = new Date(new Date().getTime() + (4 * 60 * 60 * 1000)).getDay()

  const deliveryIndex = deliveryWindows.findIndex((deliveryWindow) => {
    return deliveryWindow.dayOfWeek > currentDayOfWeek
  })

  if (deliveryIndex == -1) {
    return [deliveryWindows[0], deliveryWindows[1], deliveryWindows[2], deliveryWindows[3]]
  } else if (deliveryIndex == 5) {
    return [deliveryWindows[5], deliveryWindows[0], deliveryWindows[1], deliveryWindows[2]]
  } else if (deliveryIndex == 4) {
    return [deliveryWindows[4], deliveryWindows[5], deliveryWindows[0], deliveryWindows[1]]
  } else if (deliveryIndex == 3) {
    return [deliveryWindows[3], deliveryWindows[4], deliveryWindows[5], deliveryWindows[0]]
  } else {
    return [deliveryWindows[deliveryIndex], deliveryWindows[deliveryIndex + 1],
    deliveryWindows[deliveryIndex + 2], deliveryWindows[deliveryIndex + 3]]
  }
}

const decorateDeliveryWindows = (windows, availability) => {
  windows.forEach((window) => {
    window.active = availability[`${window.name} ${window.time}`]
  })
}

const AsyncWindows: React.FC = (data) => {
  const { deliveryWindowChange } = data

  const currentDeliveryWindows = nextDeliveryWindows()
  const deliveryWindowString = currentDeliveryWindows.map((window) => `${window.name} ${window.time}`).join(',')
  console.log(deliveryWindowString)

  const { data: deliveryResponse, error } = useSWR(
    `https://delivery-availability.elmfoods.com/.netlify/functions/delivery-windows?windowsToCheck=${deliveryWindowString}`,
    fetcher,
    { suspense: true }
  )
  decorateDeliveryWindows(currentDeliveryWindows, deliveryResponse)
  console.log(deliveryResponse)

  return (
    <div className="col-12 mt-3">
      <h4>Pick your delivery window</h4>
      {currentDeliveryWindows.map((radioDeliveryWindow, index) => {
        return (
          <div key={`deliveryWindow-${index}`}>
            <label>
              <input
                className="pr-3"
                type="radio"
                id={`deliveryWindow${index}`}
                name='deliveryWindow'
                onChange={() => { deliveryWindowChange(radioDeliveryWindow) }}
                value={`${radioDeliveryWindow.name} ${radioDeliveryWindow.time}`}
                disabled={!radioDeliveryWindow.active} />
              <span className={`pl-3 ${radioDeliveryWindow.active ? '' : 'text-black-50'}`}>
                {radioDeliveryWindow.name} {radioDeliveryWindow.time}
                {radioDeliveryWindow.active ? '' : ' (sold out)'}
              </span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

const DeliveryWindows: React.FC = (data) => {
  const { deliveryWindowChange } = data

  const fallback = <div className="col-12 h-150p">
    <h4>Loading Delivery Windows...</h4>
  </div>

  return (
    <Suspense fallback={fallback}>
      <AsyncWindows deliveryWindowChange={deliveryWindowChange} />
    </Suspense >
  )
}

export default DeliveryWindows
