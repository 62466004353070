import * as React from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { CartContext } from './shared/CartProvider'

import CartIcon from '../images/svgs/basket.svg'

interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title, hideHeader }) => (
  <StaticQuery
    query={graphql`
      query HeaderImageQuery {
        headerImage: file(relativePath: { eq: "brandImages/elm_logo.png" }) {
          size
          childImageSharp {
            fluid {
              src
              sizes
            }
          }
        }
      }
    `}
    render={_data => (
      <header className={`border-bottom d-print-none ${hideHeader ? "d-none" : "d-block"}`}>
        <div>
          <nav className="container p-0 bg-brand-dark">
            <div className="row">
              <div className="col-12 d-flex pr-0">
                <div className="mt-auto mb-auto cart position-relative cursor-pointer mr-auto invisible d-print-none">
                  <CartIcon className="cart" />
                  <div className="position-absolute bg-white cart-number text-center">1</div>
                </div>
                <Link to="/" className="py-1 py-md-3 text-center mx-auto">
                  <p className="font-weight-boldest display-2 no-underline mb-0 text-body">ELM FOODS</p>
                </Link>
                <CartContext.Consumer>
                  {cart => {
                    return (
                      <div className="my-auto cart position-relative cursor-pointer ml-auto bg-primary rounded d-print-none" onClick={cart.toggleCart}>
                        <CartIcon className="w-50 h-75 pt-1" />
                        <div className="position-absolute cart-number text-center">{cart.numItems()}</div>
                      </div>
                    )
                  }}
                </CartContext.Consumer>
              </div>
            </div>
          </nav>
        </div>
      </header >
    )}
  />
)

export default Header
