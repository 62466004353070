import React, { useState } from 'react'
import { CartContext } from '../CartProvider'
import { ProductsContext } from '../ProductsProvider'
import CartPanel from './CartPanel'
import ExtraInfoPanel from './ExtraInfoPanel'

import Close from '../../../images/icons/close.svg'

let cleaned = false

const Cart: React.FC = () => {
  const [currentState, setCurrentState] = useState('cart')

  const setInfoState = () => {
    setCurrentState('extraInfo')
  }

  const setCartState = () => {
    setCurrentState('cart')
  }

  return (
    <CartContext.Consumer>
      {(cart) => (
        <ProductsContext.Consumer>
          {(catalog) => {
            // Clean of the cart of dead skus on the first load
            setTimeout(() => {
              if (cleaned) {
                return
              }

              cart.cleanCart(catalog)
              cleaned = true
            })

            const decoratedCatalog = cart.decorateCart(catalog)
            const cartStyle = cart.visible ? { right: "0px" } : { right: "-400px" }

            let cartPanel
            if (currentState == 'cart') {
              cartPanel = <CartPanel cart={cart} catalog={decoratedCatalog} onCheckoutFn={setInfoState} />
            } else {
              cartPanel = <ExtraInfoPanel backFn={setCartState} cartItems={decoratedCatalog} recipeSlugs={cart.recipes} />
            }



            return (
              <div id="cart-display" style={cartStyle}>
                <div className="container">
                  <div className="close-button" onClick={cart.toggleCart}>
                    <Close className="img-fluid" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h3 className="display-5 mt-3 pb-4">Your Basket</h3>
                    </div>
                  </div>
                </div>
                {cartPanel}
              </div>
            )
          }}
        </ProductsContext.Consumer>
      )}
    </CartContext.Consumer>
  )
}

export default Cart
